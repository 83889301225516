import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';
import Socials from './partials/Socials';
import Image from '../elements/Image';
import Button from '../elements/Button';
import { Link } from 'react-router-dom';

import { PrismicLink, useFirstPrismicDocument, usePrismicClient } from '@prismicio/react'

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false
}

const Footer = ({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) => {

  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className
  );

  const [document] = useFirstPrismicDocument('home_page');
  const [links, setLinks] = useState();
  const client = usePrismicClient();

  // useEffect(() => {
  //   let document = client.getFirst()
  //     .then(document => {
  //       console.log(document);
  //       setLinks({
  //         termsAndConditions: document.data.body[1].primary.terms_and_conditions.url,
  //         privacyPolicy: document.data.body[1].primary.privacy_policy.url,
  //         cookiePolicy: document.data.body[1].primary.cookie_policy.url
  //       });
  //       console.log(links);
  //     }, links);
  //   console.log(document);
  // })

  return (
    <footer
      {...props}
      className={classes}
    >
      <div className="container">
        <div className={
          classNames(
            'site-footer-inner',
            topDivider && 'has-top-divider'
          )}>
          <div className="footer-top space-between text-xxs">
            <Logo logoName='logo-footer.png' width='220' height='122' />
            <div>
              <p>Soon available to download</p>
              <div className="stores">
                <Image
                  src={require(`./../../assets/images/appstore.svg`)}
                  alt="AppStore"
                  width={114}
                  height={33} />
                <Image
                  src={require(`./../../assets/images/googleplay.svg`)}
                  alt="Google Play"
                  width={114}
                  height={33} />
              </div>
              <div className="docs pt-24">
                <a className="footer-data"
                   href="https://doitapp-landingpage.cdn.prismic.io/doitapp-landingpage/43b6d1c3-112b-4af4-95ee-5ca72b122505_Terms+and+Conditions_DO-IT+App.pdf">
                  Terms and Conditions
                </a>
                <a className="footer-data"
                   href="https://doitapp-landingpage.cdn.prismic.io/doitapp-landingpage/5f71b3ab-0170-4842-b928-8666ffb01c04_Privacy+Policy_DO-IT+App.pdf">
                  Privacy Policy
                </a>
              </div>
            </div>
            <div className="footer-social">
              <p>Follow us:</p>
              <Socials />
            </div>
            <a href="https://www.buymeacoffee.com/doitapp">
              <Image
                src={require(`./../../assets/images/buyusacoffee.svg`)}
                alt="Buy us a coffee"
                width={208}
                height={60} />
            </a>
          </div>
          <div className="footer-bottom space-between text-xxs">
            <div className="footer-data">© 2022 INNOVALISTA SRL. <br />All rights reserved.</div>
            <a className="footer-data"
              href="https://doitapp-landingpage.cdn.prismic.io/doitapp-landingpage/1cff4e52-d67e-45bf-9451-d9327abcc918_2022-12-28_Terms+and+Conditions_Website+DO-IT.docx.pdf">
              Terms and Conditions
            </a>
            <a className="footer-data"
              href="https://doitapp-landingpage.cdn.prismic.io/doitapp-landingpage/bf6f6be5-8995-4401-98c5-ad0b8a261031_2022-12-28_Privacy+Policy_Website+DO-IT.docx.pdf">
              Privacy Policy
            </a>
            <a className="footer-data"
              href="https://doitapp-landingpage.cdn.prismic.io/doitapp-landingpage/d66d642b-4511-47b6-85f7-72913af5b51e_2022-12-28_Cookies+Policy_Website+DO-IT.docx.pdf">
              Cookie Policy
            </a>
            <Link to="#" id="open_preferences_center" className="footer-data">Update cookie preferences</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;

//document.data.body[1].primary.terms_and_conditions.url
