import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Socials from '../layout/partials/Socials';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'hero section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  // const onClick = () => {
  //   window.location.href = "#join-us";
  // };

  const testingGuideUrl = "https://doitapp-landingpage.cdn.prismic.io/doitapp-landingpage/e532e0e8-7f03-4620-b868-1a2e2af16ca7_Ghid+testare+-+Apple+%26+Android-2.pdf";

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="hero-content reveal-from-bottom" data-reveal-delay="200">
            <div className="illustration-three-lines">
              <h1 className="mt-0 mb-16">
                Dare more. Do more. Have fun.
              </h1>
              <p className="m-0 mb-32">
                Grow your skills with DO-IT App challenge by challenge. Exploring new activites every day has never been more fun.
              </p>
              <div>
                <Button handleOnClick={() => openInNewTab(testingGuideUrl)} tag="a" color="primary" wideMobile>
                  Test it now!
                </Button>
              </div>
            </div>
            <div className="hero-image">
              <Image
                src={require('./../../assets/images/hero-image.svg')}
                alt="Hero"
                width={900}
                height={504} />
            </div>
          </div>
          <Socials className="reveal-from-bottom" data-reveal-delay="600" />
        </div>
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;